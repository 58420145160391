<template>
  <div class="room-client-tab">
    <div class="buttons">
      <ul>
        <li :class="{ active: activeTab == 0 }" @click="changeTab(0)" v-if="user.Role == 1">{{ $localization("web-room-clienttab-mycomplaint") }}</li>
        <li :class="{ active: activeTab == 0 }" @click="changeTab(0)" v-else>{{ $localization("web-room-clienttab-compaint") }}</li>
        <li :class="{ active: activeTab == 1, disabled: currentAppointment.Status == 3 }" @click="changeTab(1)">{{ $localization("web-room-clienttab-message") }}</li>
        <li :class="{ active: activeTab == 2 }" @click="changeTab(2)" v-if="user.Role == 1">{{ $localization("web-room-clienttab-about-doctor") }}</li>
        <li :class="{ active: activeTab == 2 }" @click="changeTab(2)" v-else>{{ $localization("web-room-clienttab-diagnostic") }}</li>
      </ul>
    </div>
    <div class="diagnosis" v-if="activeTab == 0">
      <div class="date-container">
        <div class="date">{{ $moment().format(`[${$localization("web-room-clienttab-today")}], DD MMMM YYYY`) }}</div>
      </div>
      <div class="client-diagnosis">
        <div class="title">{{ currentAppointment.PatientDiagnosis.Title }}</div>
        <div class="content" v-if="currentAppointment.PatientDiagnosis.Details != null" v-html="currentAppointment.PatientDiagnosis.Details"></div>
      </div>
      <div class="client-attachment" v-if="currentAppointment.PatientDiagnosis.Attachments.length > 0">
        <div class="title">{{ $localization("web-room-clienttab-file-attachments") }}</div>
        <ul class="list">
          <li class="list-item" v-for="(item, index) in currentAppointment.PatientDiagnosis.Attachments" :key="index">
            <div class="file">
              <img :src="getExtension(item.Extension)" />
            </div>
            <div class="details">
              <div class="title">{{ item.FilenameReal }}</div>
              <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
            </div>
            <div class="icon-download" @click="downloadFile(item.ObjectKey)"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat" v-if="activeTab == 1">
      <div class="chat-area" id="scroll-list">
        <div class="date-container">
          <div class="date">{{ $moment().format(`[${$localization("web-room-clienttab-today")}], DD MMMM YYYY`) }}</div>
        </div>
        <ul class="chat-list">
          <li v-for="(message, index) in $store.state.appointments.currentMessage" :key="index" :class="{ me: message.SenderUserId == user.ID ? true : false }">
            <div class="info">
              <div class="fullname">{{ message.SenderUser }}</div>
              <div class="date">{{ $moment(message.CreatedDateTime).format("HH:mm") }}</div>
            </div>
            <div class="content">
              <div class="attachment" v-if="message.Attachments.length > 0">
                <div class="top"><i class="icon-attachment-2"></i> {{ $localization("web-room-clienttab-attached-file") }}</div>
                <div class="file" v-for="(item, index) in message.Attachments" :key="index" @click="downloadFile(item.ObjectKey)">
                  <div class="doc-container">
                    <i class="icon-document"></i>
                  </div>
                  <div class="info">
                    <div class="title">{{ item.FilenameReal }}</div>
                    <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
                  </div>
                </div>
              </div>
              <div class="text" v-html="messageDisplay(message.MessageText)"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="chat-sender">
        <el-input type="textarea" :rows="2" :placeholder="$localization('web-room-clienttab-write-message-here')" class="chat-text-input" v-model="text" @keyup.enter="sendMessage" :autofocus="true"></el-input>
        <div class="action-group">
          <el-button type="primary" class="primary-btn send-button btn-shadow" :loading="loading == 1" @click="sendMessage()">
            <i class="icon-send-2" v-if="loading != 1"></i>
          </el-button>
          <el-upload
            :action="$client.defaults.baseURL + 'Account/UploadFile?relatedItemId=' + $route.params.id + '&type=1'"
            :headers="{
              Authorization: $client.defaults.headers.common['Authorization'],
            }"
            :show-file-list="false"
            :on-success="fileSuccess"
            :on-progress="fileOnProggress"
            v-if="attachments.length == 0">
            <el-button type="primary" :loading="loading == 2" class="secondarary-btn flush-button">
              <i class="icon-attachment-2" v-if="loading != 2 && attachments.length == 0"></i>
            </el-button>
          </el-upload>
          <el-button v-else type="primary" :loading="loading == 2" class="secondarary-btn flush-button" style="margin-left: 0">
            <i class="icon-circle-x" v-if="loading != 2 && attachments.length > 0" @click="clearAttachments"></i>
          </el-button>
        </div>
      </div>
    </div>
    <div class="doctor" v-if="activeTab == 2 && user.Role == 1">
      <div class="date-container">
        <div class="date">{{ $moment().format(`[${$localization("web-room-clienttab-today")}], DD MMMM YYYY`) }}</div>
      </div>
      <div class="doctor-info">
        <div class="title">{{ $localization("web-room-clienttab-doctor-name") }}</div>
        <div class="content">{{ currentAppointment.DoctorUser.Title }}</div>
      </div>
      <div class="doctor-info">
        <div class="title">{{ $localization("web-room-clienttab-doctor-title") }}</div>
        <div class="content">{{ currentAppointment.DoctorUser.Specialist }}</div>
      </div>
      <div class="doctor-info" v-if="currentDoctor != null && currentDoctor.Biography != null">
        <div class="title">{{ $localization("web-room-clienttab-about") }}</div>
        <div class="content">{{ currentDoctor.Biography }}</div>
      </div>
      <div class="doctor-attachment" v-if="currentDoctor != null && currentDoctor.Attachments != null">
        <div class="title">{{ $localization("web-room-clienttab-attachments") }}</div>
        <ul class="list">
          <li class="list-item" v-for="(item, index) in currentDoctor.Attachments" :key="index">
            <div class="file">
              <img :src="getExtension(item.Extension)" />
            </div>
            <div class="details">
              <div class="title">{{ item.FilenameReal }}</div>
              <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
            </div>
            <div class="icon-download" @click="downloadFile(item.ObjectKey)"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="doctor" v-if="activeTab == 2 && user.Role == 2">
      <div class="date-container">
        <div class="date">{{ $moment().format(`[${$localization("web-room-clienttab-today")}], DD MMMM YYYY`) }}</div>
      </div>

      <div class="button-group-admin vertical">
        <!-- <el-button class="big-btn" type="primary" @click="inspectionInformation = true" :disabled="onSave" :loading="onSave"><i class="icon-steteskop" v-if="onSave == false"></i>Muayene Bilgisi</el-button>

        <el-button class="big-btn" type="primary" @click="eNabiz = true" :disabled="onSave" :loading="onSave"><i class="icon-enabiz" v-if="onSave == false"></i>E-nabız</el-button>

        <el-button class="big-btn" type="primary" @click="prescriptionsScreen = true" :disabled="onSave" :loading="onSave"><i class="icon-recete" v-if="onSave == false"></i>Reçete Yaz</el-button>
        <el-button class="big-btn" type="primary" @click="sickReportScreen = true" :disabled="onSave" :loading="onSave"><i class="icon-report" v-if="onSave == false"></i>Rapor Yaz</el-button> -->
        <!-- <el-button class="big-btn" type="primary" @click="exportGlobal" :disabled="onSave" :loading="onSave"><i class="icon-report" v-if="onSave == false"></i>Muayene Bilgisi</el-button> -->
        <el-button class="big-btn" type="primary" @click="exportGlobal" :disabled="onSave" :loading="onSave"><i class="icon-steteskop" v-if="onSave == false"></i>{{ $localization("web-room-clienttab-inspection-info") }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dummy: null,
      doctorDiagnosis: {
        AppointmentId: this.$route.params.id,
        DoctorDiagnosis: null,
        DoctorNotes: null,
        DiagnosisCodes: [],
        TransferHospital: null,
      },
      activeTab: 1,
      text: "",
      attachments: [],
      loading: false,
      timeOut: null,
      // prescriptionsScreen: false,
      // sickReportScreen: false,
      // inspectionInformation: false,
      // eNabiz: false,
      onSave: false,
      // payload: {
      //   UserId: null,
      //   AppointmentId: null,
      //   ProvisionType: null,
      //   RecipeSubtype: null,
      //   PrescriptionsList: [], //reçete listesi
      // },
      optionsListCode: [],
      optionsMedicineList: [],
      medicineList: [],
      ValidationErrors: [],
      prescriptions: [],
    };
  },
  async beforeCreate() {
    // await this.$store.dispatch("getEnums");
  },
  async beforeMount() {
    await this.setCurrentAppointment();
    if (this.currentAppointment.Status == 3) {
      this.activeTab = 2;
    }
    this.$store.commit("clearMessage");
    await this.$store.dispatch("getCurrentAppointmentMessages", {
      AppointmentId: this.$route.params.id,
      Page: 1,
      PageSize: 100,
    });
    this.scrollToBottom();
  },
  async mounted() {},
  methods: {
    async exportGlobal() {
      var clone = JSON.parse(JSON.stringify(this.currentAppointment));
      console.log("Apiden donen  : ");
      //console.log(clone);

      //!!! FC 16/03/24++ clone.price=0 ise default/variables den alınıyordu ve oradaki fiyat sabit
      // Sistemedki fiyat ayarlandi
      let tmpprice = clone.Price;
      if (tmpprice == 0) {
        if (this.$store.getters.getAppointmentPrice == 0) {
          tmpprice = this.appointmentDiscountPrice;
        } else tmpprice = this.$store.getters.getAppointmentPrice;
      }
      if (tmpprice == 0) {
        tmpprice = 190;
      } // her zaman fatura kesilecek

      //console.log("tmpprıce clişenttab: ");
      //console.log(tmpprice);

      var payload = null;
      var createdDate = this.$moment(clone.CreatedDateTime).add(2, "minutes").format("YYYYMMDDHHmm");
      var endDate = this.$moment(clone.EndDate).add(2, "minutes").format("YYYYMMDDHHmm");
      let tmp = document.createElement("DIV");
      let tmp2 = document.createElement("DIV");
      tmp.innerHTML = clone.PatientDiagnosis.Details;
      tmp2.innerHTML = clone.PatientDiagnosis.Title;
      var title = tmp2.innerText;
      var details = tmp.innerText;

      var complaint = "Şikayet konusu: " + title + " - Şikayet detayları: " + details;
      var city = null;
      var district = null;
      if (clone.User.Kid == null) {
        var gender = null;
        var birtdateUser = null;

        var msj = "";

        if (clone.User.Gender == 1) {
          gender = "KADIN";
        } else if (clone.User.Gender == 2) {
          gender = "ERKEK";
        } else {
          gender = "";
        }
        if (clone.User.BirthDate) {
          birtdateUser = this.$moment(clone.User.BirthDate).format("YYYYMMDD0000");
        } else {
          birtdateUser = "";
        }
        // this.$store.state.appointments.currentMessage.Attachments.length > 0 ||

        if (clone.PatientDiagnosis.Attachments.length > 0) {
          msj = 1;
        } else {
          msj = 2;
        }

        // if (clone.User.Address) {
        //   adress1 = clone.User.Address.split(" ", 1);
        // }

        if (clone.User.IdentityNumber == null) {
          clone.User.IdentityNumber = 0;
        }
        if (clone.User.ForeignIdentityNumber == null) {
          clone.User.ForeignIdentityNumber = "";
        }

        if (clone.User.PassportNumber == null) {
          clone.User.PassportNumber = "";
        }
        if (clone.User.FirstName == null) {
          clone.User.FirstName = "";
        }
        if (clone.User.LastName == null) {
          clone.User.LastName = "";
        }
        if (clone.User.PhoneNumber == null) {
          clone.User.PhoneNumber = "";
        }
        if (clone.User.Email == null) {
          clone.User.Email = "";
        }
        if (clone.User.Address == null) {
          clone.User.Address = "";
        }
        if (clone.User.City == null) {
          city = "";
        } else {
          city = clone.User.City.Title;
        }
        if (clone.User.District == null) {
          district = "";
        } else {
          district = clone.User.District.Title;
        }
        if (clone.DoctorUser.IdentityNumber == null) {
          clone.DoctorUser.IdentityNumber = 0;
        }
        if (clone.User.Height == null) {
          clone.User.Height = 0;
        }
        if (clone.User.Weight == null) {
          clone.User.Weight = 0;
        }

        //!!! FC 15/03/24++ Kurum Bilgileri
        if (clone.User.InstitutionCode == null) {
          clone.User.InstitutionCode = "";
        }
        if (clone.User.InstitutionId == null) {
          clone.User.InstitutionId = "";
        }
        if (clone.User.Kurum == null) {
          clone.User.Kurum = "";
        }

        payload = {
          PaymentId: clone.PaymentId,
          Price: tmpprice /*clone.Price == 0 ? this.appointmentDiscountPrice : clone.Price,*/,
          GELDIGI_YER: "GLOBALE_AKTAR",
          HASTA_KIMLIK_NUMARASI: clone.User.IdentityNumber,
          YABANCI_HASTA_KIMLIK_NUMARASI: "",
          PASAPORT_NO: clone.User.PassportNumber,
          AD: clone.User.FirstName,
          SOYAD: clone.User.LastName,
          DOGUM_TARIHI: birtdateUser,
          CINSIYET: gender,
          UYRUK: clone.User.CitizenshipCode,
          TELEFON_NUMARASI: clone.User.PhoneNumber,
          RANDEVU_ZAMANI: createdDate,
          EPOSTA_ADRESI: clone.User.Email,
          ACIK_ADRES: clone.User.Address,
          ACIK_ADRES_IL: city,
          ACIK_ADRES_ILCE: district,
          KABUL_ZAMAN: createdDate,
          HEKIM_KIMLIK_NUMARASI: clone.DoctorUser.IdentityNumber,

          // "RANDEVU_ZAMANI" : this.dummy,
          MUAYENE_BITIS_TARIHI: endDate,
          BOY: clone.User.Height,
          KILO: clone.User.Weight,
          // "HEKIM_KIMLIK_NUMARASI" : this.dummy,
          USBS_MUAYENE_BASLANGIC_ZAMANI: createdDate,
          USBS_MUAYENE_BITIS_ZAMANI: endDate,
          USBS_MUAYENE_GORUNTU_KAYDI: 1,
          USBS_MUAYENE_DOSYA_GONDERIM: msj,
          UBS_MUAYENE_SES_KAYDI: 1,

          HASTA_NOTU: complaint,
          MESAJ_ALINMA_ZAMANI: createdDate,
          HASTA_KAYIT_IDENTITY: clone.User.Id, //USERIN Id si
          MUAYENE_KAYIT_IDENTITY: clone.ID, //apoointment id si
          DOKTOR_KAYIT_IDENTITY: clone.DoctorUser.Id, //doktor idsi
          KURUM_KODU: clone.User.InstitutionCode, // kurum kodu !!! FC 15/03/24++
          KURUM_ADI: clone.User.Kurum, // kurum adı !!! FC 15/03/24++
          DOKTOR_NOTU: clone.PatientDiagnosis.DoctorNotes, // Doktor Notları !!! FC 15/05/24++
          TotalAppointment: clone.User.TotalAppointment,
          CompletedAppointment: clone.User.CompletedAppointment,
          CancelledAppointment: clone.User.CancelledAppointment,
        };
      } else {
        var genderKid = null;
        var birtdateUserKid = null;
        var msjKid = "";

        if (clone.User.Kid.Gender == 1) {
          genderKid = "KADIN";
        } else if (clone.User.Kid.Gender == 2) {
          genderKid = "ERKEK";
        } else {
          genderKid = "";
        }
        if (clone.User.Kid.BirthDate) {
          birtdateUserKid = this.$moment(clone.User.Kid.BirthDate).format("YYYYMMDD0000");
        } else {
          birtdateUserKid = "";
        }
        // this.$store.state.appointments.currentMessage.Attachments.length > 0 ||
        if (clone.PatientDiagnosis.Attachments.length > 0) {
          msjKid = 1;
        } else {
          msjKid = 2;
        }

        if (clone.User.Kid.IdentityNumber == null) {
          clone.User.Kid.IdentityNumber = "";
        }

        // if (clone.User.PassportNumber == null) {
        //   clone.User.PassportNumber = "";
        // }
        if (clone.User.FirstName == null) {
          clone.User.FirstName = "";
        }
        if (clone.User.LastName == null) {
          clone.User.LastName = "";
        }
        if (clone.User.PhoneNumber == null) {
          clone.User.PhoneNumber = "";
        }
        if (clone.User.Email == null) {
          clone.User.Email = "";
        }
        if (clone.User.Address == null) {
          clone.User.Address = "";
        }
        if (clone.User.City == null) {
          city = "";
        } else {
          city = clone.User.City.Title;
        }
        if (clone.User.District == null) {
          district = "";
        } else {
          district = clone.User.District.Title;
        }
        if (clone.DoctorUser.IdentityNumber == null) {
          clone.DoctorUser.IdentityNumber = 0;
        }

        if (clone.User.Kid.Height == null) {
          clone.User.Kid.Height = 0;
        }
        if (clone.User.Kid.Weight == null) {
          clone.User.Kid.Weight = 0;
        }
        //!!! FC 15/03/24++ Kurum Bilgileri
        if (clone.User.InstitutionCode == null) {
          clone.User.InstitutionCode = "";
        }
        if (clone.User.InstitutionId == null) {
          clone.User.InstitutionId = "";
        }
        if (clone.User.Kurum == null) {
          clone.User.Kurum = "";
        }
        payload = {
          PaymentId: clone.PaymentId,
          Price: tmpprice /*clone.Price == 0 ? this.appointmentDiscountPrice : clone.Price,*/,
          GELDIGI_YER: "GLOBALE_AKTAR",
          HASTA_KIMLIK_NUMARASI: clone.User.Kid.IdentityNumber,
          YABANCI_HASTA_KIMLIK_NUMARASI: "",
          PASAPORT_NO: clone.User.Kid.PasaportNumber,
          AD: clone.User.Kid.FirstName,
          SOYAD: clone.User.Kid.LastName,
          DOGUM_TARIHI: birtdateUserKid,
          CINSIYET: genderKid,
          UYRUK: clone.User.CitizenshipCode,
          TELEFON_NUMARASI: clone.User.PhoneNumber,
          RANDEVU_ZAMANI: createdDate,
          EPOSTA_ADRESI: clone.User.Email,
          ACIK_ADRES: clone.User.Address,
          ACIK_ADRES_IL: city,
          ACIK_ADRES_ILCE: district,
          KABUL_ZAMAN: createdDate,
          HEKIM_KIMLIK_NUMARASI: clone.DoctorUser.IdentityNumber,

          // "RANDEVU_ZAMANI" : this.dummy,
          MUAYENE_BITIS_TARIHI: endDate,
          BOY: clone.User.Kid.Height,
          KILO: clone.User.Kid.Weight,
          // "HEKIM_KIMLIK_NUMARASI" : this.dummy,
          USBS_MUAYENE_BASLANGIC_ZAMANI: createdDate,
          USBS_MUAYENE_BITIS_ZAMANI: endDate,
          USBS_MUAYENE_GORUNTU_KAYDI: 1,
          USBS_MUAYENE_DOSYA_GONDERIM: msjKid,
          UBS_MUAYENE_SES_KAYDI: 1,

          HASTA_NOTU: complaint,
          MESAJ_ALINMA_ZAMANI: createdDate,
          HASTA_KAYIT_IDENTITY: clone.User.Kid.ID, //USERIN Id si
          MUAYENE_KAYIT_IDENTITY: clone.ID, //apoointment id si
          DOKTOR_KAYIT_IDENTITY: clone.DoctorUser.Id, //doktor idsi
          KURUM_KODU: clone.User.InstitutionCode, // kurum kodu !!! FC 15/03/24++
          KURUM_ADI: clone.User.Kurum, // kurum adı !!! FC 15/03/24++
          DOKTOR_NOTU: clone.PatientDiagnosis.DoctorNotes, // Doktor Notları !!! FC 15/05/24++
          TotalAppointment: clone.User.TotalAppointment,
          CompletedAppointment: clone.User.CompletedAppointment,
          CancelledAppointment: clone.User.CancelledAppointment,
        };
      }
      console.log("Globale gonderilen : ");
      console.log(payload);
      fetch("http://localhost:8081/", {
        headers: {
          accept: "application/json, text/plain, */*",
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
        method: "POST",
        mode: "no-cors",
      });
      this.$message.success(this.$localization("web-room-clienttab-inspection-info-sent"));
    },
    messageDisplay(message) {
      if (message.includes("http")) {
        return `<a target="_blank" href="${message}">${message}</a>`;
      } else {
        return `<p>${message}</p>`;
      }
    },
    async setCurrentAppointment() {
      //eslint-disable-line
      var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id }); //eslint-disable-line
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        setTimeout(async () => {
          this.$router.push("/");
        }, 2000);
      } else {
        this.doctorDiagnosis.DoctorDiagnosis = res.data.Data.Appointment.PatientDiagnosis.DoctorDiagnosis;
        this.doctorDiagnosis.DoctorNotes = res.data.Data.Appointment.PatientDiagnosis.DoctorNotes;
        this.doctorDiagnosis.DiagnosisCodes = res.data.Data.Appointment.PatientDiagnosis.DiagnosisCodes;
        this.doctorDiagnosis.TransferHospital = res.data.Data.Appointment.PatientDiagnosis.TransferHospital;
      }
    },
    clearAttachments() {
      this.attachments = [];
    },
    fileOnProggress() {
      this.loading = 2;
    },
    fileSuccess(res) {
      if (res.HasError) {
        this.$message.error(res.Message);
      } else {
        this.attachments.push(res.Item);
      }
      this.loading = false;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.jQuery("#scroll-list").animate({ scrollTop: this.jQuery("#scroll-list").prop("scrollHeight") }, 250);
      });
    },
    async sendMessage() {
      this.loading = 1;
      var res = await this.$client.post("/Appointment/SendMessage", {
        AppointmentId: this.currentAppointment.ID,
        MessageText: this.text,
        Attachments: this.attachments,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.text = "";
        this.attachments = [];
      }
      this.loading = false;
      this.scrollToBottom();
    },
    changeTab(no) {
      this.activeTab = no;
    },
    async downloadFile(key) {
      var res = await this.$client.post("/Account/GeneratePreSignedUrl", { ObjectKey: key });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        window.open(res.data.Data.Url, "_blank");
      }
    },

    async diagnosisSearch(query) {
      if (query.length > 4) {
        this.loading = true;
        this.filter.SearchTerm = query;
        await this.$store.dispatch("getDiagnosticList", this.filter);

        setTimeout(() => {
          this.loading = false;
          this.optionsListCode = this.listCode.filter((item) => {
            return item.Description.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.optionsListCode = [];
      }
    },
    async prescriptionsSearch(query) {
      if (query.length > 4) {
        this.loading = true;
        var temp = await this.$client.post("/Medicine/List", { Page: 1, PageSize: 100, SearchTerm: query });
        this.medicineList = temp.data.Data.Items;

        setTimeout(() => {
          this.loading = false;
          this.optionsMedicineList = this.medicineList.filter((item) => {
            return item.Title.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.optionsMedicineList = [];
      }
    },
  },
  watch: {
    doctorDiagnosis: {
      handler: function () {
        if (this.user.Role == 2) {
          window.clearTimeout(this.timeOut);
          this.timeOut = setTimeout(async () => {
            await this.$store.dispatch("setDoctorNotes", this.doctorDiagnosis);
          }, 2000);
        }
      },
      deep: true,
    },
    "currentAppointment.Status": function () {
      if (this.currentAppointment.Status == 3) {
        this.activeTab = 2;
      }
    },
  },
  computed: {
    getEnums() {
      return this.$store.state.auth.enums;
    },
    currentDoctor() {
      return this.$store.getters.getCurrentDoctor;
    },
    user() {
      return this.$store.getters.getUser;
    },
    currentAppointment() {
      return this.$store.getters.getCurrentAppointment;
    },
    listCode() {
      return this.$store.getters.getDiagnosticList;
    },
    filter() {
      return this.$store.getters.getDiagnosticFilter;
    },
    appointmentDiscountPrice() {
      return this.$store.getters.getDiscountAppointmentPrice;
    },
  },
};
</script>

<style lang="less">
.doctor-attachment {
  .el-select {
    width: 100%;
    margin-bottom: 16px;

    .el-input {
      .el-input__inner {
        border-radius: 14px;

        &:focus {
          border-color: #234a98;
        }
      }
    }
  }
}

.button-group-admin {
  justify-content: space-between;
  padding: 0 28px;
}

// -------------------------

.prescriptions {
  width: 50%;
  border-radius: 14px !important;

  .el-dialog__header {
    padding: 0;
  }

  .close {
    position: absolute;
    right: 1rem;
    cursor: pointer !important;
    top: 1rem;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
    background-color: #eaedf0;

    .icon-close {
      width: 100%;
      height: 100%;
    }
  }

  .el-dialog__body {
    padding: 20px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .all-parts {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .head-tag {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #44566c;
  }

  .mid-tag {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #44566c;
  }

  .el-select,
  .el-input {
    width: 100%;
    height: 38px;
  }

  .flex-x {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .grey-bg {
    background-color: #f8fafb;
    padding: 16px;
    margin-top: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .hospital-check {
      border-color: #234a98 !important;
      width: 100%;

      .span {
        color: #234a98 !important;
      }

      .el-checkbox__label {
        color: #234a98 !important;
      }

      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #234a98 !important;
        border-color: #234a98 !important;
      }

      .el-checkbox.is-bordered {
        border-radius: 16px !important;
      }
    }

    .doctor-info {
      display: flex;
      flex-direction: column;
      padding: 0 28px;
      gap: 8px;

      & > .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        color: #202020;
      }

      & > .content {
        padding: 24px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #202020;
        border-radius: 14px;
        background-color: white;
      }

      & > .el-textarea {
        textarea {
          border: none;
          background-color: #fff;
          border-radius: 14px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #202020;
          height: 120px;
          resize: none;
        }
      }
    }

    .doctor-attachment {
      display: flex;
      flex-direction: column;
      padding: 0 28px;
      gap: 8px;

      & > .el-textarea {
        textarea {
          border: none;
          background-color: #fff;
          border-radius: 14px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #202020;
          height: 120px;
          resize: none;
        }
      }

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        color: #202020;
      }

      & > .list {
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > .list-item {
          background-color: #f8fafb;
          border-radius: 5px;
          padding: 16px;
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          & > .file {
            img {
              width: 31px;
              height: 40px;
            }
          }

          & > .details {
            display: flex;
            flex-direction: column;
            gap: 4px;

            & > .title {
              font-weight: 400;
              font-size: 13px;
              line-height: 15px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              color: #44566c;
            }

            & > .byte {
              font-weight: 400;
              font-size: 13px;
              line-height: 15px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              color: #8697a8;
            }
          }

          & > .icon-download {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }
  }

  .part {
    & > .input-container {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .text {
      margin-bottom: 8px;
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      color: #8697a8;

      .red {
        color: #ff3d57;
      }
    }

    .el-input__inner {
      padding: 12px 19px;
    }

    input,
    textarea {
      background-color: #f8fafb;
      font-size: 15px;
      font-weight: 400;
      color: #44566c;
      border: none;

      &:focus {
        border-color: inherit;
      }
    }

    .el-textarea {
      textarea {
        padding: 16px 18px;
      }
    }
  }

  .medicine-list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .part {
      display: flex;
      gap: 16px;
      background-color: #f8fafb;
      padding: 12px 12px;
      border-radius: 16px;
      flex-direction: column;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        button {
          background-color: #ff3d57;
          border: none;
          border-radius: 50%;
          padding: 10px;
        }
      }

      .el-col {
        .text {
          margin-bottom: 8px;
        }
      }

      .text {
        color: #44566c;
      }
    }

    .el-input__inner,
    .el-input-number,
    textarea {
      background-color: #fff !important;
    }
  }

  .el-switch__label.is-active {
    color: #2586c7;
  }

  .error {
    display: block;
    margin-top: 8px;
  }
}

.button-group-admin {
  padding: 0px;
  justify-content: flex-end;
  gap: 36px !important;

  &.vertical {
    flex-direction: column;
    margin: 48px 0;
  }

  .big-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 32px !important;
    padding: 24px;
    font-size: 24px;
    background-color: #234a98;
    box-shadow: 4px 4px 10px rgba(37, 134, 199, 0.5);
    border: none;

    &:hover,
    &:active,
    &:focus {
      background-color: #234a98 !important;
      color: white !important;
    }

    border-radius: 16px;

    span {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        height: 24px !important;
        width: 24px !important;
        background: #fff !important;
      }
    }
  }
}
</style>
