export default {
    setCountryList(state, data) {
        state.countryList = data;
    },
    setCityList(state, data) {
        state.cityList = data;
    },
    setDistrictList(state, data) {
        state.districtList = data;
    },
    setPolicyList(state, data) {
        state.policyList = data;
    }
}