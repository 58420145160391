<template>
  <div id="doctorHome">
    <div class="title">{{ $localization("web-doctor-home-main-page") }}</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization("web-doctor-home-my-meets") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="allcard">
      <div class="card" v-for="(item, index) in chartInfo" :key="index">
        <div class="card-left">
          <div class="card-tag">{{ item.Title }}</div>
          <div class="card-desc">{{ item.Description }}</div>
          <el-row type="flex" class="row-bg" justify="space-between" align="middle">
            <el-col :span="12">
              <div class="card-total-number">{{ item.Count }}</div>
            </el-col>
            <el-col :span="12">
              <el-row type="flex" align="middle" class="row-bg" justify="end">
                <i class="icon-trade-up" :class="{ plus: item.IncreaseRate >= 0 ? true : false }"></i>
                <div class="card-percent" :class="{ plus: item.IncreaseRate >= 0 ? true : false }">{{ item.IncreaseRate }}</div>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="bar-container today-meet">
          <div class="one-day" v-for="(stat, index) in item.Statistics" :key="index">
            <div class="stick">
              <div class="bar" :class="{ active: stat.Today }" :style="{ height: stat.Percentage + 'px' }"></div>
            </div>
            <div class="text">{{ $moment(stat.CalculatedDateRangeMax).format("dddd")[0] }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-general">
      <div class="search-status" :style="{ visibility: activeTable == 1 ? 'hidden' : '' }">
        <div class="status">
          {{ $localization("web-doctor-home-show") }}
          <el-select v-model="pastFilter.PageSize" :placeholder="$localization('web-doctor-home-please-select')">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="pastFilter.Status" :placeholder="$localization('web-doctor-home-please-select')" clearable @clear="clearStatus">
            <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'AppointmentStatus').Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
          </el-select>
        </div>
        <div class="search">
          <el-input placeholder="Kayıt Ara" v-model="pastFilter.SearchTerm" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>
      <el-row class="buttons">
        <el-button class="meetButton" :class="{ active: activeTable == 1 ? true : false }" @click="activeTable = 1">{{ $localization("web-doctor-home-waiting-appointments") }}</el-button>
        <el-button class="meetButton" :class="{ active: activeTable == 2 ? true : false }" @click="activeTable = 2">{{ $localization("web-doctor-home-past-appointments") }}</el-button>
      </el-row>
      <div class="table">
        <el-table :data="activeTable == 1 ? $store.state.appointments.pendingAppointments.filter((x) => x.DoctorUser == null || (x.DoctorUser != null && x.DoctorUser.Id == user.ID)).sort((a, b) => new Date(a.InterviewInfo.AppointmentDate) - new Date(b.InterviewInfo.AppointmentDate)) : pastAppoinments.Appointments" style="width: 100%">
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
            </div>
          </template>
          <el-table-column label="Id" width="120">
            <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
          </el-table-column>
          <el-table-column :label="$localization('web-doctor-home-patient-upper')" min-width="170">
            <template slot-scope="scope">
              <div class="user-info">
                <div class="user-avatar" v-if="scope.row.User.FirstName != null && scope.row.User.LastName != null">
                  <div class="short">{{ scope.row.User.FirstName[0] + scope.row.User.LastName[0] }}</div>
                </div>
                <div class="user-name">
                  <span v-if="scope.row.User.Kid != null"> {{ scope.row.User.Kid.FirstName + " " + scope.row.User.Kid.LastName + " " + $localization("web-doctor-home-kid") }} </span>
                  <span v-else> {{ scope.row.User.Title }} </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$localization('web-doctor-home-patient-institution')" width="200">
            <template slot-scope="scope" v-if="scope.row.User != null">
              {{ scope.row.User.Kurum }}
            </template>
          </el-table-column>
          <el-table-column prop="diagnosis" :label="$localization('web-doctor-home-complaint-upper')" v-if="activeTable != 2" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.PatientDiagnosis.Title != null ? scope.row.PatientDiagnosis.Title : "..." }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="diagnosis" label="TANI" v-if="activeTable != 1" min-width="120">
      <template slot-scope="scope">
        {{ scope.row.PatientDiagnosis.DoctorDiagnosis != null ? scope.row.PatientDiagnosis.DoctorDiagnosis : "..." }}
      </template>
    </el-table-column> -->
          <el-table-column :label="$localization('web-doctor-home-platform-upper')" width="180">
            <template slot-scope="scope">
              {{ getEnumsDisplay("DevicePlatform", scope.row.AppointmentRequestFrom) }}
            </template>
          </el-table-column>
          <el-table-column prop="price" :label="$localization('web-doctor-home-price-upper')" v-if="activeTable == 2" width="180">
            <template slot-scope="scope"> {{ scope.row.PaymentInfo.PaidPrice }}TL </template>
          </el-table-column>
          <el-table-column prop="WaitingTime" :label="$localization('web-doctor-home-waiting-time-upper')" v-if="activeTable == 1" width="150">
            <template slot-scope="scope"> {{ scope.row.PendingDurationInMinutes }} {{ $localization("web-doctor-home-minute") }} </template>
          </el-table-column>
          <el-table-column prop="status" :label="$localization('web-doctor-home-status-upper')" width="150">
            <template slot-scope="scope">
              <div class="color-dot">
                <div class="dot" :class="{ yellow: scope.row.Status == 1, red: scope.row.Status == 4, green: scope.row.Status == 3, yellow: scope.row.Status == 5 }"></div>
                {{ getEnumsDisplay("AppointmentStatus", scope.row.Status) }}
              </div>
            </template>
          </el-table-column>
          <!-- ++ 05.05.2023 NC Yaptığım görüşmeler bölümüne Global'e git olarak TANI  butonu ilave edildi.el-button ClientTab.vue dan aındı.scope.row appointment'ı getiren liste görevi görmektedir.Buradan Appointment id aldık.ExportGlobal'e aktardık'-->
          <el-table-column label="MUAYENE " v-if="activeTable == 2" width="250">
            <template slot-scope="scope">
              <el-button class="sendButton" type="primary" @click="exportGlobal(scope.row.ID)">{{ $localization("web-room-clienttab-inspection-info") }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="StarRating" :label="$localization('web-doctor-home-rate-upper')" v-if="activeTable == 2" width="320">
            <template slot-scope="scope">
              <el-rate v-if="scope.row.RateInfo.IsRated" v-model="scope.row.RateInfo.Rate" disabled text-color="#ff9900" score-template="{value} points"> </el-rate>
              <span v-else>{{ $localization("web-doctor-home-no-rate") }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$localization('web-doctor-home-meet-status-upper')" v-if="activeTable == 1" :key="activeTable" width="250">
            <template slot-scope="scope">
              <!-- <el-button class="meeting-button" :loading="loading == scope.row.ID" :disabled="scope.$index != 0 || scope.row.Status==5" :class="{ emergency: scope.$index == 0 && scope.row.Status!=5 , planner:scope.row.Status==5}" type="text" @click="joinAppointment(scope.row.ID)"> <i v-if="loading == false" class="el-icon-video-camera"></i> {{ scope.row.DoctorUser != null && scope.row.DoctorUser.Id == user.ID ? "GÖRÜŞMEYE DEVAM ET" : "GÖRÜŞMEYE KATIL" }}</el-button> -->
              <!-- <el-button class="meeting-button" :loading="loading == scope.row.ID" :disabled="scope.row.Status == 5" :class="{ planner: scope.row.Status == 5 }" type="text" @click="openPolicy(2, scope.row.ID)"> <i v-if="loading == false" class="el-icon-video-camera"></i> {{ scope.row.DoctorUser != null && scope.row.DoctorUser.Id == user.ID ? "GÖRÜŞMEYE KATIL" : "GÖRÜŞMEYE KATIL" }}</el-button> -->
              <el-button class="meeting-button" :loading="loading == scope.row.ID" :disabled="scope.row.Status == 5" :class="{ planner: scope.row.Status == 5 }" type="text" @click="isDoctorOk(scope.row.ID)"> <i v-if="loading == false" class="el-icon-video-camera"></i> {{ scope.row.DoctorUser != null && scope.row.DoctorUser.Id == user.ID ? $localization("web-doctor-home-join-appointment-upper") : $localization("web-doctor-home-join-appointment-upper") }}</el-button>
            </template>
          </el-table-column>

          <el-table-column :label="activeTable == 1 ? $localization('web-doctor-home-request-date-upper') : $localization('web-doctor-home-date-upper')" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY, HH:mm") }}
            </template>
          </el-table-column>

          <el-table-column :label="$localization('web-doctor-home-appointment-date-upper')" width="200" v-if="activeTable == 1">
            <template slot-scope="scope">
              <div class="color-dot">
                <div v-if="scope.row.InterviewInfo.AppointmentDate">{{ $moment(scope.row.InterviewInfo.AppointmentDate).format("Do MMMM YYYY, HH:mm") }}</div>
                <div v-else>...</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="109" align="center" v-if="activeTable == 2" :key="activeTable">
            <template slot-scope="scope">
              <el-button type="text">
                <router-link :to="'doktor/gorusme-detay/' + scope.row.ID"><i class="icon-dots"></i></router-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" v-if="activeTable == 2">
        <div class="info">{{ pastFilter.PageSize * pastFilter.Page - 10 }}-{{ pastFilter.PageSize * pastFilter.Page > pastAppoinments.TotalCount ? pastAppoinments.TotalCount : pastFilter.PageSize * pastFilter.Page }} {{ $localization("web-doctor-home-between-record-showing") }} {{ $localization("web-doctor-home-total-records") }} {{ pastAppoinments.TotalCount }}</div>
        <el-pagination background :current-page.sync="pastFilter.Page" :page-size="pastFilter.PageSize" layout="prev, pager, next" :total="pastAppoinments.TotalCount"></el-pagination>
      </div>
      <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
        <PolicyDialog :data="policy" @checked="checked" />
      </el-dialog>

      <el-dialog :visible.sync="isDoctorOkDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="dialog">
          <div class="form">
            <div class="title">{{ $localization("web-doctor-home-mic-cam-permission") }}</div>
            <div class="text">{{ $localization("web-doctor-home-mic-cam-permission-description") }}</div>
            <div class="buttons">
              <el-button class="primary-btn" @click="joinAppointment(appointmentIdReady)">{{ $localization("web-doctor-home-confirm") }}</el-button>
              <el-button class="secondarary-btn" @click="isDoctorOkDialog = false">{{ $localization("web-doctor-home-cancel") }}</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PolicyDialog from "../../components/PolicyDialog.vue";
import * as ActionNames from "@/store/action-names";

export default {
  name: "Home",
  components: {
    PolicyDialog,
  },
  data() {
    return {
      appointmentIdReady: null,
      isDoctorOkDialog: false,
      screenWidth: 0,

      loading: false,
      scope: null,
      policyControl: false,
      policy: null,
      chartInfo: [],
      activeTable: 1,
      pagesize: [10, 20, 30, 40],
      pastAppoinments: [],
      policyDialog: false,
      pastFilter: {
        PageSize: 10,
        Page: 1,
        Status: null,
        SearchTerm: null,
        PastAppointments: true,
      },
    };
  },

  async created() {
    await this.$store.dispatch("getListUserAppointments", this.$store.state.appointments.filter);
    var res = await this.$client.post("/Appointment/List", this.pastFilter);
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.pastAppoinments = res.data.Data;
    }

    var stat = await this.$client.post("/Doctor/AppointmentStatistics", {});
    if (stat.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.chartInfo = stat.data.Data.Statistics;
    }
  },
  async beforeMount() {
    await this.$store.dispatch("getChildrenList");
    //!!! FC 16/03/24++ Globale Gonderimde ana fiyat gerektigi serveden alıyoruz
    // Çunku muayene fiyatı 0 olsa bile fatura kesiliyor
    await this.$store.dispatch(ActionNames.GET_APPOINTMENT_PRICE);
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },

  methods: {
    isDoctorOk(id) {
      this.isDoctorOkDialog = true;
      this.appointmentIdReady = id;
    },
    clearStatus() {
      this.pastFilter.Status = null;
    },
    async openPolicy(type, scope) {
      this.scope = scope;
      var res = await this.$client.post("/Definition/GetPolicyByType", {
        Type: type,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.policy = res.data.Data.Policy;
        this.policyDialog = true;
      }
    },
    checked(number, type) {
      var switchChecked = type == 1;
      switch (number) {
        case 2:
          this.policyControl = switchChecked;
          break;
      }
      this.policyDialog = false;
      this.joinAppointment(this.scope);
    },
    async joinAppointment(scope) {
      this.isDoctorOkDialog = false;
      // if (this.policyControl == false) {
      //   return;
      // }
      window.doNotThrowMe = true;
      await this.$client.post("/Appointment/ShutdownOtherWindows", {});
      setTimeout(() => {
        window.doNotThrowMe = false;
      }, 1000);

      this.loading = scope;

      var res = await this.$client.post("/Doctor/JoinAppointment", { AppointmentId: scope });
      if (!res.data.HasError) {
        this.$router.push("/doktor/gorusme-odasi/" + scope);

        this.$emit("stopAlarm");
        //#endregion
      } else {
        this.$message.error(res.data.Message);
      }
      this.loading = false;
    },

    //++06/05/23 NC yaptığım görüsmeler kısmında muayene bilgisi butonunda exportglobal(row.scop.ıde appointmentId yi pasladı) appointment get ten row.scop'tan gelen id ye göre appointment ı cektim ve json'a pasladım.
    async exportGlobal(id) {
      var res = await this.$client.post("/Appointment/Get", { AppointmentId: id }); //eslint-disable-line
      console.log(res);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        var clone = JSON.parse(JSON.stringify(res.data.Data.Appointment));

        //console.log("Apiden donen  : ");
        //console.log(clone);

        //!!! FC 16/03/24++ clone.price=0 ise default/variables den alınıyordu ve oradaki fiyat sabit
        // Sistemedki fiyat ayarlandi
        let tmpprice = clone.Price;
        if (tmpprice == 0) {
          if (this.$store.getters.getAppointmentPrice == 0) {
            tmpprice = this.appointmentDiscountPrice;
          } else tmpprice = this.$store.getters.getAppointmentPrice;
        }
        if (tmpprice == 0) {
          tmpprice = 190;
        } // her zaman fatura kesilecek

        //console.log("tmpprice : ");
        //console.log(tmpprice);

        var payload = null;
        var createdDate = this.$moment(clone.CreatedDateTime).add(2, "minutes").format("YYYYMMDDHHmm");
        var endDate = this.$moment(clone.EndDate).add(2, "minutes").format("YYYYMMDDHHmm");
        let tmp = document.createElement("DIV");
        let tmp2 = document.createElement("DIV");
        tmp.innerHTML = clone.PatientDiagnosis.Details;
        tmp2.innerHTML = clone.PatientDiagnosis.Title;
        var title = tmp2.innerText;
        var details = tmp.innerText;

        var complaint = "Şikayet konusu: " + title + " - Şikayet detayları: " + details;
        var city = null;
        var district = null;
        if (clone.User.Kid == null) {
          var gender = null;
          var birtdateUser = null;

          var msj = "";

          if (clone.User.Gender == 1) {
            gender = "KADIN";
          } else if (clone.User.Gender == 2) {
            gender = "ERKEK";
          } else {
            gender = "";
          }
          if (clone.User.BirthDate) {
            birtdateUser = this.$moment(clone.User.BirthDate).format("YYYYMMDD0000");
          } else {
            birtdateUser = "";
          }
          // this.$store.state.appointments.currentMessage.Attachments.length > 0 ||

          if (clone.PatientDiagnosis.Attachments.length > 0) {
            msj = 1;
          } else {
            msj = 2;
          }

          // if (clone.User.Address) {
          //   adress1 = clone.User.Address.split(" ", 1);
          // }

          if (clone.User.IdentityNumber == null) {
            clone.User.IdentityNumber = 0;
          }
          if (clone.User.ForeignIdentityNumber == null) {
            clone.User.ForeignIdentityNumber = "";
          }

          if (clone.User.PassportNumber == null) {
            clone.User.PassportNumber = "";
          }
          if (clone.User.FirstName == null) {
            clone.User.FirstName = "";
          }
          if (clone.User.LastName == null) {
            clone.User.LastName = "";
          }
          if (clone.User.PhoneNumber == null) {
            clone.User.PhoneNumber = "";
          }
          if (clone.User.Email == null) {
            clone.User.Email = "";
          }
          if (clone.User.Address == null) {
            clone.User.Address = "";
          }
          if (clone.User.City == null) {
            city = "";
          } else {
            city = clone.User.City.Title;
          }
          if (clone.User.District == null) {
            district = "";
          } else {
            district = clone.User.District.Title;
          }
          if (clone.DoctorUser.IdentityNumber == null) {
            clone.DoctorUser.IdentityNumber = 0;
          }
          if (clone.User.Height == null) {
            clone.User.Height = 0;
          }
          if (clone.User.Weight == null) {
            clone.User.Weight = 0;
          }
          //!!! FC 15/03/24++ Kurum Bilgileri
          if (clone.User.InstitutionCode == null) {
            clone.User.InstitutionCode = "";
          }
          if (clone.User.InstitutionId == null) {
            clone.User.InstitutionId = "";
          }
          if (clone.User.Kurum == null) {
            clone.User.Kurum = "";
          }

          payload = {
            PaymentId: clone.PaymentId,
            Price: tmpprice /* clone.Price == 0 ? this.appointmentDiscountPrice : clone.Price,*/,
            GELDIGI_YER: "GLOBALE_AKTAR",
            HASTA_KIMLIK_NUMARASI: clone.User.IdentityNumber,
            YABANCI_HASTA_KIMLIK_NUMARASI: "",
            PASAPORT_NO: clone.User.PassportNumber,
            AD: clone.User.FirstName,
            SOYAD: clone.User.LastName,
            DOGUM_TARIHI: birtdateUser,
            CINSIYET: gender,
            UYRUK: clone.User.CitizenshipCode,
            TELEFON_NUMARASI: clone.User.PhoneNumber,
            RANDEVU_ZAMANI: createdDate,
            EPOSTA_ADRESI: clone.User.Email,
            ACIK_ADRES: clone.User.Address,
            ACIK_ADRES_IL: city,
            ACIK_ADRES_ILCE: district,
            KABUL_ZAMAN: createdDate,
            HEKIM_KIMLIK_NUMARASI: clone.DoctorUser.IdentityNumber,

            // "RANDEVU_ZAMANI" : this.dummy,
            MUAYENE_BITIS_TARIHI: endDate,
            BOY: clone.User.Height,
            KILO: clone.User.Weight,
            // "HEKIM_KIMLIK_NUMARASI" : this.dummy,
            USBS_MUAYENE_BASLANGIC_ZAMANI: createdDate,
            USBS_MUAYENE_BITIS_ZAMANI: endDate,
            USBS_MUAYENE_GORUNTU_KAYDI: 1,
            USBS_MUAYENE_DOSYA_GONDERIM: msj,
            UBS_MUAYENE_SES_KAYDI: 1,

            HASTA_NOTU: complaint,
            MESAJ_ALINMA_ZAMANI: createdDate,
            HASTA_KAYIT_IDENTITY: clone.User.Id, //USERIN Id si
            MUAYENE_KAYIT_IDENTITY: clone.ID, //apoointment id si
            DOKTOR_KAYIT_IDENTITY: clone.DoctorUser.Id, //doktor idsi
            KURUM_KODU: clone.User.InstitutionCode, // kurum kodu !!! FC 15/03/24++
            KURUM_ADI: clone.User.Kurum, // kurum adı !!! FC 15/03/24++
            DOKTOR_NOTU: clone.PatientDiagnosis.DoctorNotes, // Doktor Notları !!! FC 15/05/24++
            TotalAppointment: clone.User.TotalAppointment,
            CompletedAppointment: clone.User.CompletedAppointment,
            CancelledAppointment: clone.User.CancelledAppointment,

          };
        } else {
          var genderKid = null;
          var birtdateUserKid = null;
          var msjKid = "";

          if (clone.User.Kid.Gender == 1) {
            genderKid = "KADIN";
          } else if (clone.User.Kid.Gender == 2) {
            genderKid = "ERKEK";
          } else {
            genderKid = "";
          }
          if (clone.User.Kid.BirthDate) {
            birtdateUserKid = this.$moment(clone.User.Kid.BirthDate).format("YYYYMMDD0000");
          } else {
            birtdateUserKid = "";
          }
          // this.$store.state.appointments.currentMessage.Attachments.length > 0 ||
          if (clone.PatientDiagnosis.Attachments.length > 0) {
            msjKid = 1;
          } else {
            msjKid = 2;
          }

          if (clone.User.Kid.IdentityNumber == null) {
            clone.User.Kid.IdentityNumber = "";
          }

          // if (clone.User.PassportNumber == null) {
          //   clone.User.PassportNumber = "";
          // }
          if (clone.User.FirstName == null) {
            clone.User.FirstName = "";
          }
          if (clone.User.LastName == null) {
            clone.User.LastName = "";
          }
          if (clone.User.PhoneNumber == null) {
            clone.User.PhoneNumber = "";
          }
          if (clone.User.Email == null) {
            clone.User.Email = "";
          }
          if (clone.User.Address == null) {
            clone.User.Address = "";
          }
          if (clone.User.City == null) {
            city = "";
          } else {
            city = clone.User.City.Title;
          }
          if (clone.User.District == null) {
            district = "";
          } else {
            district = clone.User.District.Title;
          }
          if (clone.DoctorUser.IdentityNumber == null) {
            clone.DoctorUser.IdentityNumber = 0;
          }

          if (clone.User.Kid.Height == null) {
            clone.User.Kid.Height = 0;
          }
          if (clone.User.Kid.Weight == null) {
            clone.User.Kid.Weight = 0;
          }
          //!!! FC 15/03/24++ Kurum Bilgileri
          if (clone.User.InstitutionCode == null) {
            clone.User.InstitutionCode = "";
          }
          if (clone.User.InstitutionId == null) {
            clone.User.InstitutionId = "";
          }
          if (clone.User.Kurum == null) {
            clone.User.Kurum = "";
          }

          payload = {
            PaymentId: clone.PaymentId,
            Price: tmpprice, //  /* clone.Price == 0 ? this.appointmentDiscountPrice : clone.Price,*/
            GELDIGI_YER: "GLOBALE_AKTAR",
            HASTA_KIMLIK_NUMARASI: clone.User.Kid.IdentityNumber,
            YABANCI_HASTA_KIMLIK_NUMARASI: "",
            PASAPORT_NO: clone.User.Kid.PasaportNumber,
            AD: clone.User.Kid.FirstName,
            SOYAD: clone.User.Kid.LastName,
            DOGUM_TARIHI: birtdateUserKid,
            CINSIYET: genderKid,
            UYRUK: clone.User.CitizenshipCode,
            TELEFON_NUMARASI: clone.User.PhoneNumber,
            RANDEVU_ZAMANI: createdDate,
            EPOSTA_ADRESI: clone.User.Email,
            ACIK_ADRES: clone.User.Address,
            ACIK_ADRES_IL: city,
            ACIK_ADRES_ILCE: district,
            KABUL_ZAMAN: createdDate,
            HEKIM_KIMLIK_NUMARASI: clone.DoctorUser.IdentityNumber,
    
            // "RANDEVU_ZAMANI" : this.dummy,
            MUAYENE_BITIS_TARIHI: endDate,
            BOY: clone.User.Kid.Height,
            KILO: clone.User.Kid.Weight,
            // "HEKIM_KIMLIK_NUMARASI" : this.dummy,
            USBS_MUAYENE_BASLANGIC_ZAMANI: createdDate,
            USBS_MUAYENE_BITIS_ZAMANI: endDate,
            USBS_MUAYENE_GORUNTU_KAYDI: 1,
            USBS_MUAYENE_DOSYA_GONDERIM: msjKid,
            UBS_MUAYENE_SES_KAYDI: 1,

            HASTA_NOTU: complaint,
            MESAJ_ALINMA_ZAMANI: createdDate,
            HASTA_KAYIT_IDENTITY: clone.User.Kid.ID, //USERIN Id si
            MUAYENE_KAYIT_IDENTITY: clone.ID, //apoointment id si
            DOKTOR_KAYIT_IDENTITY: clone.DoctorUser.Id, //doktor idsi4
            KURUM_KODU: clone.User.InstitutionCode, // kurum kodu !!! FC 15/03/24++
            KURUM_ADI: clone.User.Kurum, // kurum adı !!! FC 15/03/24++
            DOKTOR_NOTU: clone.PatientDiagnosis.DoctorNotes, // Doktor Notları !!! FC 15/05/24++
            TotalAppointment: clone.User.TotalAppointment,
            CompletedAppointment: clone.User.CompletedAppointment,
            CancelledAppointment: clone.User.CancelledAppointment,
          };
        }
        console.log("Globale gonderilen : ");
        console.log(payload);

        fetch("http://localhost:8081/", {
          headers: {
            accept: "application/json, text/plain, */*",
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
          method: "POST",
          mode: "no-cors",
        });
        this.$message.success(this.$localization("web-room-clienttab-inspection-info-sent"));
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getEnums() {
      return this.$store.state.auth.enums;
    },
    getAppointments() {
      return this.$store.state.appointments.appointments.Appointments;
    },
    filter() {
      return this.$store.state.appointments.filter;
    },
    children() {
      return this.$store.getters.getChildrenList;
    },
    appointmentDiscountPrice() {
      return this.$store.getters.getDiscountAppointmentPrice;
    },
  },
  watch: {
    pastFilter: {
      handler: async function () {
        var res = await this.$client.post("/Appointment/List", this.pastFilter);
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.pastAppoinments = res.data.Data;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less">
#doctorHome {
  padding: 42px 30px;

  .user-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .user-avatar {
      flex-shrink: 0;
      width: 30px;
      height: 30px;

      .short {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: orange;
        height: 100%;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
  }

  .allcard {
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media screen and (max-width: 1298px) {
      & {
        flex-direction: column;
      }

      & > .card {
        width: 100%;
      }
    }

    .el-row {
      justify-content: flex-start;
    }
  }

  .card {
    width: 33.333%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    background-color: white;
    min-height: 180px;
    border-radius: 10px;
    box-shadow: 0px 32px 32px rgba(169, 194, 209, 0.1), 0px 8px 16px rgba(169, 194, 209, 0.05);
    padding: 20px 20px 20px 40px;

    .card-left {
      height: 142px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      gap: 10px;

      i {
        transform: rotate(180deg);
        background-color: #ff3d57;

        &.plus {
          background-color: #09b66d;
          transform: rotate(360deg);
        }
      }
    }
  }

  .card-tag {
    font-size: 20px;
    color: #44566c;
    font-weight: 700;
  }

  .card-desc {
    font-size: 15px;
    color: #44566c;
    font-weight: 400;
  }

  .card-total-number {
    font-size: 24px;
    color: #44566c;
    font-weight: 500;
  }

  .card-percent {
    &.plus {
      background-color: #09b66d;
    }

    background-color: #ff3d57;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 5px;

    height: 18px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: white;
  }

  .one-day {
    padding-top: 10px;
    flex-direction: column;
    gap: 3px;
    display: flex;

    .stick {
      position: relative;
      height: 100px;
      width: 10px;
      border-radius: 16px;
      background: #f4f4f4;

      & > .bar {
        position: absolute;
        width: 10px;
        bottom: 0;
        left: 0;
        background: #b3c0ce;
        border-radius: 16px;
      }
    }
  }

  .bar-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    &.today-meet {
      & > .one-day {
        .stick {
          .bar.active {
            background-color: #2586c7;
          }
        }
      }
    }

    &.all-meet {
      & > .one-day {
        .stick {
          .bar.active {
            background-color: #09b66d;
          }
        }
      }
    }

    &.today-time {
      & > .one-day {
        .stick {
          .bar.active {
            background-color: #ff8a48;
          }
        }
      }
    }
  }

  /*++05/05/23 NC Global e göndermek için button tipi*/

  .sendButton {
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    border: none;
    color: #ffffff !important;
    background-color: #234a98 !important;
  }

  .sendButton:hover {
    color: #010521 !important;
    background-color: #f0f1f6 !important;
  }

  .table-general {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .el-table {
      box-shadow: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 42px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          ::placeholder {
            color: #8697a8 !important;
          }

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }

        .cell {
          word-break: break-word;
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .el-select {
          border-radius: 5px;
          max-width: 200px;

          &:first-child {
            width: 75px;
          }

          &:last-child {
            width: 200px;
          }

          border: none;

          .el-input {
            i {
              background: unset;
            }

            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }

          .el-input__inner::placeholder {
            color: #44566c;
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #234a98;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }

    & > .buttons {
      background-color: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding-top: 20px;
      padding-bottom: 1rem;

      & > :first-child {
        margin-left: 48px !important;
      }

      .meetButton {
        width: 265px;
        height: 34px;
        align-items: center;
        padding: 0 !important;
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
        border: none;
        background-color: #f8fafb;

        &.active {
          background-color: #234a98 !important;
          color: #ffffff !important;

          :hover {
            background-color: #234a98 !important;
            color: #ffffff !important;
          }
        }
      }

      :hover {
        color: #234a98;
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }

  .meeting-button {
    &.emergency {
      height: 100% !important;
      width: 100% !important;
      background-color: #0081ff !important;
      color: white !important;
      font-size: 15px !important;
      line-height: 17px !important;
      font-weight: 500 !important;
    }

    height: 100% !important;
    width: 100% !important;
    background-color: #a08bdb44 !important;
    color: #234a98 !important;
    font-size: 15px !important;
    line-height: 17px !important;
    font-weight: 500 !important;

    &.planner {
      height: 100% !important;
      width: 100% !important;
      background-color: #d9dada !important;
      color: #8697a8 !important;
      font-size: 15px !important;
      line-height: 17px !important;
      font-weight: 500 !important;
    }
  }
}
</style>
