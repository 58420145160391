<template>
  <el-container class="layout-container">
    <el-aside>
      <div class="logo">
        <img src="../assets/img/logo.svg" class="logo" v-if="isCollapse == false" />
        <img src="../assets/img/Compact.png" class="mobile" v-else />
      </div>
      <el-row class="tac">
        <el-col :span="24">
          <el-menu :default-active="activeMenu" class="el-menu-vertical-demo side-menu" :collapse="isCollapse" :router="true">
            <!-- Dinamik olarak menü öğelerini render et -->
            <template v-for="(item, index) in defaultMenus">
              <!-- Normal menü öğesi -->
              <el-menu-item 
                v-if="!item.subMenu && (!item.roleRequired || item.roleRequired.length === 0 || item.roleRequired.includes(user.Role))" 
                :key="index" 
                :index="item.path">
                <i :class="item.icon"></i>
                <span slot="title" v-html="item.title"></span>
              </el-menu-item>
              
              <!-- Alt menülü öğe -->
              <el-submenu 
                :key="'submenu-'+index" 
                v-else-if="item.subMenu && (item.roleRequired && !item.roleRequired.length === 0 || item.roleRequired.includes(user.Role))"
                :index="item.index">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span slot="title" style="font-weight: 500; font-size: 15px; line-height: 18px; color: #44566c; padding-left: 10px">{{ item.title }}</span>
                </template>
                <el-menu-item 

                  v-for="(subItem, subIndex) in item.subMenu" 
                  :key="'sub-'+index+'-'+subIndex" 
                  :index="subItem.path">{{ subItem.title }}</el-menu-item>
              </el-submenu>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header>
        <div class="left">
          <img src="../assets/img/Compact.png" class="mobile" />
          <div @click="isCollapse = !isCollapse" class="menu-collapse"></div>
          <div @click="drawerMenu = !drawerMenu" class="menu-drawer"></div>
        </div>

        <div class="right">
          <div class="buttons-group">
            <div class="user-info">
              <div class="user-avatar">
                <div class="short">{{ user.FirstName[0] }}{{ user.LastName[0] }}</div>
              </div>
              <div class="user-name">
                <span>{{ user.FirstName }} {{ user.LastName }}</span>
              </div>
            </div>
          </div>
          <div class="menu-icon-logout" @click="logout()"></div>
        </div>
      </el-header>
      <portal-target name="new-header"> </portal-target>
      <el-main class="admin-main-container">
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: this.$route.path,
      isCollapse: false,
      roleGroups: [
        {
          role: 12,
          menus: [
            {
              name: "Adminler",
            },
          ],
        },
      ],
      // Menü öğelerini içeren dizi
      defaultMenus: [
        {
          path: '/admin',
          title: 'Dashboard',
          icon: 'menu-icon-dashboard',
          roleRequired: [12,11,10]

        },
        {
          path: '/admin/adminler',
          title: 'Adminler',
          icon: 'icon-user-crown',
          roleRequired: [12]
        },
        {
          path: '/admin/kullanicilar',
          title: 'Kullanıcılar',
          icon: 'menu-icon-user',
          roleRequired: [12,11,10]

        },
        {
          path: '/admin/mesajlar',
          title: 'Mesajlar',
          icon: 'menu-icon-messages',
          roleRequired: [12]

        },
        {
          path: '/admin/dosyalar',
          title: 'Dosyalar',
          icon: 'menu-icon-folder',
          roleRequired: [12]

        },
        {
          path: '/admin/segmentasyon',
          title: 'Segmentasyon',
          icon: 'icon-user-crown',
          roleRequired: [12]

        },
        {
          path: '/admin/genel-segment',
          title: 'Genel Segmentasyon',
          icon: 'icon-user-crown',
          roleRequired: [12]

        },
        {
          path: '/admin/doktorlar',
          title: 'Doktorlar',
          icon: 'menu-icon-contacts',
          roleRequired: [12]

        },
        {
          path: '/admin/hemsireler',
          title: 'Hemşireler',
          icon: 'menu-icon-nurse',
          roleRequired: [12]

        },
        {
          path: '/admin/branslar',
          title: 'Branşlar',
          icon: 'menu-icon-contacts',
          roleRequired: [12]

        },
        {
          path: '/admin/gecmis-gorusmeler',
          title: 'Tüm Görüşmeler',
          icon: 'menu-icon-details',
          roleRequired: [12,11,10]

        },
        {
          path: '/admin/odeme-gecmisi',
          title: 'Ödeme Geçmişi',
          icon: 'menu-icon-credit-card',
          roleRequired: [12]

        },
        {
          path: '/admin/islem-gecmisi',
          title: 'İşlem Geçmişi',
          icon: 'menu-icon-clock-rotate',
          roleRequired: [12]

        },
        {
          path: '/admin/doktor-not-gecmisi',
          title: 'Doktor Not Geçmişi',
          icon: 'menu-icon-sticky',
          roleRequired: [12]

        },
        {
          path: '/admin/faq',
          title: 'Sıkça Sorulan Sorular',
          icon: 'menu-icon-help',
          roleRequired: [12]

        },
        {
          path: '/admin/destek-talebi',
          title: 'Destek Talepleri',
          icon: 'menu-icon-drafts',
          roleRequired: [12]

        },
        {
          // Blog alt menüsü
          index: '1',
          title: 'Blog',
          icon: 'menu-icon-forms',
          subMenu: [
            { path: '/admin/kategoriler', title: 'Kategoriler' },
            { path: '/admin/makaleler', title: 'Makaleler' }
          ],
          roleRequired: [12]
        },
        {
          // Web Sitesi alt menüsü
          index: '2',
          title: 'Web Sitesi',
          icon: 'icon-home',
          subMenu: [
            { path: '/admin/mobilanasayfakartlar', title: 'Mobil Uygulama Kartlar' },
            { path: '/admin/onboardings', title: 'Onboarding' },
            { path: '/admin/home-comments', title: 'Yorumlar' },
            { path: '/admin/sabitalanlar', title: 'Sabit Alanlar' },
            { path: '/admin/web-dosyalari', title: 'Dosyalar' },
            { path: '/admin/psikolojik-testler', title: 'Psikolojik Testler' }
          ],
          roleRequired: [12]
        },
        {
          // Yerelleştirme alt menüsü
          index: '3',
          title: 'Yerelleştirme',
          icon: 'menu-icon-language',
          subMenu: [
            { path: '/admin/sistem-dilleri', title: 'Sistem Dilleri' },
            { path: '/admin/yerellestirme', title: 'Yerelleştirme' }
          ],
          roleRequired: [12]
        },
        {
          path: '/admin/sozlesmeler',
          title: 'Sözleşmeler',
          icon: 'icon-pages',
          roleRequired: [12]
        },
        {
          path: '/admin/skrskaydi',
          title: 'SKRS İşlemleri',
          icon: 'menu-icon-credit-card',
          roleRequired: [12]
        },
        {
          path: '/admin/kurumlar',
          title: 'Kurumlar',
          icon: 'menu-icon-credit-card',
          roleRequired: [12]
        },
        {
          path: '/admin/hizmetler',
          title: 'Hizmetler',
          icon: 'menu-icon-credit-card',
          roleRequired: [12]
        }
      ]
    };
  },
  name: "ClientLayout",
  mounted() {
    if (window.innerWidth < 768) {
      this.isCollapse = true;
    }
    this.$socket = this.$io(process.env.VUE_APP_SOCKETURL, {
      transports: ["websocket"],
      upgrade: false,
      query: {
        token: localStorage.getItem("token"),
      },
    });
    this.$socket.off("reconnect_attempt");
    this.$socket.on("reconnect_attempt", () => {
      console.log("reconnect_attempt");
    });
    this.$socket.off("disconnect");
    this.$socket.on("disconnect", () => {
      console.log("disconnect");
    });
    this.$socket.off("connect");
    this.$socket.on("connect", () => {
      this.$socket.emit("subscribe", {
        channel: "presence-user-" + this.user.ID,
        token: "",
      });
    });
    this.$socket.off("subscribe_success");
    this.$socket.on("subscribe_success", (room) => {
      console.log(room);
      this.$socket.off("presence-user-" + this.user.ID + "-waitingApprovalCount");
      this.$socket.on("presence-user-" + this.user.ID + "-waitingApprovalCount", (message) => {
        console.log(message, "mesaj");

        this.user.UnreadNoteRequestCount += 1;
      });
    });
    this.$socket.emit("subscribe", {
      channel: "presence-user-" + this.user.ID,
      token: "",
    });
  },
  methods: {
    async logout() {
      await this.$client.post("/Account/SignOut");
      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    "$route.params": function () {
      this.activeMenu = this.$route.path;
    },
  },
};
</script>
<style lang="less">
.el-menu-collapse {
  width: unset;
}
</style>
