import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";

export default {
  async getTests(context, payload) {
    var res = await client.post("/Test/List", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setTests",res.data.Data.Items)
    }
  },
  async addTest(context, payload) {
    var res = await client.post("/Test/AddOrUpdate", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getTests", {});
    }
    return res.data;
  },
  async getTest(context, payload) {
    var res = await client.post("/Test/Get", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
    return res.data;
  },
  async deleteTest(context, payload) {
    var res = await client.post("/Test/Delete", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }else {
      Message.success(res.data.Message);
      context.dispatch("getTests", {});
    }
  },
};
