import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
const state = {
  doctorSettings: {
    sessionDuration: 45,
    breakDuration: 15,
    monthLater:2,
    workingDays: [
      {
        isWorking: true,
        startTime: '09:00',
        endTime: '17:00',
        hasLunchBreak: true,
        lunchBreakStart: '12:00',
        lunchBreakEnd: '13:00'
      },
      {
        isWorking: true,
        startTime: '09:00',
        endTime: '17:00',
        hasLunchBreak: true,
        lunchBreakStart: '12:00',
        lunchBreakEnd: '13:00'
      },
      {
        isWorking: true,
        startTime: '09:00',
        endTime: '17:00',
        hasLunchBreak: true,
        lunchBreakStart: '12:00',
        lunchBreakEnd: '13:00'
      },
      {
        isWorking: true,
        startTime: '09:00',
        endTime: '17:00',
        hasLunchBreak: true,
        lunchBreakStart: '12:00',
        lunchBreakEnd: '13:00'
      },
      {
        isWorking: true,
        startTime: '09:00',
        endTime: '17:00',
        hasLunchBreak: true,
        lunchBreakStart: '12:00',
        lunchBreakEnd: '13:00'
      },
      {
        isWorking: false,
        startTime: '09:00',
        endTime: '13:00',
        hasLunchBreak: false,
        lunchBreakStart: '',
        lunchBreakEnd: ''
      },
      {
        isWorking: false,
        startTime: '09:00',
        endTime: '13:00',
        hasLunchBreak: false,
        lunchBreakStart: '',
        lunchBreakEnd: ''
      }
    ],
    offDays: []
  },
  doctor: {
    id: null,
    name: null,
    email: null,
    phone: null,
  }
};
const getters = {
  doctorSettings: (state) => state.doctorSettings,
  doctor: (state) => state.doctor,
};
const actions = {
  getDoctor: async ({ commit }, id) => {
    const response = await client.post(`/Doctor/Get`, {
      Id: id
    });  
    if (response.data.HasError) {
      Message.error(response.data.Message);
    } else {
      commit('setDoctor', response.data.Data.User);
    }
  },
};
const mutations = {
  setDoctor(state, doctor) {
    state.doctor = doctor;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
