<template>
  <div class="patient-profile">
    <p class="p-head">{{ $localization("web-client-my-profile") }}</p>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization("web-client-my-profile") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main-content">
      <el-row>
        <el-col :span="24" :xs="24" :sm="24" :md="8" :lg="6" class="user-info">
          <div class="profile-photo">
            <img v-if="user.Image != null" width="100%" :src="user.Image" alt="" />
            <div class="profile-name">
              <span>{{ user.FirstName }} {{ user.LastName }}</span>
              <i class="icon-task" @click="openEditProfileDialog"></i>
            </div>
          </div>
          <div class="profile-bottom">
            <div class="item" v-if="user.Email != null">
              <i class="icon-message"></i><span>{{ user.Email }}</span>
            </div>
            <div class="item" v-if="user.PhoneNumber != null">
              <i class="icon-mobile"></i><span>{{ user.PhoneNumber }}</span>
            </div>
            <div class="coupon-code">
              <p class="p-head">Kupon Kodu</p>
              <div class="coupon-code-input">
                <el-input v-model="couponCode" placeholder="Kupon Kodu" :disabled="couponLocked"></el-input>
                <el-button class="primary-btn" v-if="!couponLocked" @click="applyCoupon" :loading="couponLoading">Uygula</el-button>
                <el-button class="primary-btn" @click="couponLocked = !couponLocked" v-else>Güncelle</el-button>
              </div>
            </div>
            <div class="last-appointment">
              <p class="p-head">{{ $localization("web-client-passappointments") }} ({{ pastTotalCount }})</p>
              <div class="profile-slider owl-theme owl-carousel" v-if="pastAppointment.length > 0">
                <div class="slider-item" v-for="item in pastAppointment" :key="item.ID">
                  <div class="dr-card" v-if="item.DoctorUser != null">
                    <div class="dr-photo">
                      <img :src="item.DoctorUser.Image" alt="" />
                    </div>
                    <div class="dr-information">
                      <div class="dr-name">
                        {{ item.DoctorUser.Specialist }}
                        {{ item.DoctorUser.Title }}
                      </div>
                      <div class="date">
                        {{ $moment(item.EndDate).format("DD.MM.YYYY") }}
                      </div>
                    </div>
                    <el-button class="slider-btn" @click="goDetails(item.ID)"><i class="icon-arrow"></i></el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="24" :xs="24" :sm="24" :md="{span: 15, offset: 1}" :lg="{span: 17, offset: 1}"  class="right-section">
          <div class="child-list-container">
            <div class="p-head">
              <span>Yakınlarım ve Çocuklarım</span>
              <i class="icon-plus" @click="addUpdateChildDialogVisible = true" style="cursor: pointer"></i>
            </div>
            <div class="child-list-head">
              <div class="child-list-head-item">İsim</div>
              <div class="child-list-head-item">Doğum Tarihi</div>
              <div class="child-list-head-item">Cinsiyet</div>
              <div class="child-list-head-item"></div>
            </div>
            <div class="child-list-item" v-for="item in childrenList" :key="item.ID">
              <div class="child-list-item-item user-info-sec">
                <div class="name-short">{{ item.FirstName.charAt(0) }}{{ item.LastName.charAt(0) }}</div>
                <div class="name-full">{{ item.FirstName }} {{ item.LastName }}</div>
              </div>
              <div class="child-list-item-item">{{ $moment(item.BirthDate).format("DD.MM.YYYY") }}</div>
              <div class="child-list-item-item">{{ genderEnums.Enums.find((x) => x.Key == item.Gender).DisplayName }}</div>
              <div class="child-list-item-item">
                <i class="icon-delete" @click="deleteChild(item.ID)"></i>
              </div>
            </div>
          </div>
          <div class="saved-credit-card">
            <div class="p-head">
              <span>{{ $localization("web-client-saved-cards") }}</span>
              <i class="icon-plus" @click="savePaymentMethodDialogVisible = true" style="cursor: pointer"></i>
            </div>
            <div class="credit-card-list">
              <div class="credit-card-item" v-for="card in user.PaymentMethods" :key="card.Id">
                <div class="credit-card-info">
                  <div class="card-type">
                    <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                    <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
                  </div>
                  <div class="card-details">
                    <div class="card-number">{{ card.CardFamily }} {{ card.Bin }}</div>
                  </div>
                </div>
                <div class="card-actions">
                  <i class="icon-delete" @click="deleteCard(card.Id)"></i>
                </div>
              </div>
              <div class="no-saved-card" v-if="user.PaymentMethods.length == 0">Kayıtlı kredi kartınız bulunmamaktadır.</div>
            </div>
          </div>
        
          
          <div class="invoice-address-container">
            <div class="p-head">
              <span>{{ $localization("web-client-invoice-address") }}</span>
              <i class="icon-task" @click="openEditDialog" style="cursor: pointer"></i>
            </div>
            <div class="invoice-list">
              <div class="invoice-list-row">
                <div class="invoice-list-label">İl</div>
                <div class="invoice-list-value">{{ user.InvoiceAddress.City }}</div>
              </div>
              <div class="invoice-list-row">
                <div class="invoice-list-label">İlçe</div>
                <div class="invoice-list-value">{{ user.InvoiceAddress.District }}</div>
              </div>
              <div class="invoice-list-row">
                <div class="invoice-list-label">Adres</div>
                <div class="invoice-list-value">{{ user.InvoiceAddress.Address }}</div>
              </div>
              <div class="invoice-list-row">
                <div class="invoice-list-label">Posta Kodu</div>
                <div class="invoice-list-value">{{ user.InvoiceAddress.ZipCode }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <EditInvoiceDialog :visible.sync="editInvoiceDialogVisible" @update:visible="editInvoiceDialogVisible = false" />
    <EditProfileDialog :visible.sync="editProfileDialogVisible" @update:visible="editProfileDialogVisible = false" />
    <SavePaymentMethodDialog :visible.sync="savePaymentMethodDialogVisible" @update:visible="savePaymentMethodDialogVisible = false" />
    <AddUpdateChild :visible.sync="addUpdateChildDialogVisible" @update:visible="addUpdateChildDialogVisible = false" />
  </div>
</template>

<script>
import EditInvoiceDialog from "@/components/PatientProfile/EditInvoiceDialog.vue";
import EditProfileDialog from "@/components/PatientProfile/EditProfileDialog.vue";
import SavePaymentMethodDialog from "@/components/PatientProfile/SavePaymentMethodDialog.vue";
import AddUpdateChild from "@/components/PatientProfile/AddUpdateChild.vue";
import { MessageBox } from "element-ui";
export default {
  name: "NewProfile",
  components: {
    EditInvoiceDialog,
    EditProfileDialog,
    SavePaymentMethodDialog,
    AddUpdateChild,
  },
  data() {
    return {
      pastAppointment: [],
      pastTotalCount: 0,
      editInvoiceDialogVisible: false,
      editProfileDialogVisible: false,
      couponCode: "",
      couponLocked: false,
      couponLoading: false,
      showCardDialog: false,
      savePaymentMethodDialogVisible: false,
      addUpdateChildDialogVisible: false,
    };
  },
  async mounted() {
    await this.getPastAppointment();
    await this.$store.dispatch("getChildrenList", this.filter);
    var response = await this.$client.post("/Appointment/GetCouponCode", {});
    if (response.data.HasError) {
      this.$message.error(response.data.Message);
    } else {
      this.couponCode = response.data.Data?.CouponCode || "";
    }
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
    // URL'den addPaymentMethod parametresini kontrol et
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("addPaymentMethod") === "success") {
      this.$message.success("Kart başarıyla eklendi");
      // Parametreyi URL'den temizle
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      // Kullanıcı bilgilerini güncelle
      this.$store.dispatch("getUser");
    }
    // eslint-disable-next-line no-console
    console.log("Profile component loaded");
  },
  methods: {
    async applyCoupon() {
      this.couponLoading = true;
      var response = await this.$client.post("/Appointment/UpdateCouponCode", {
        CouponCode: this.couponCode,
      });
      if (response.data.HasError) {
        this.$message.error(response.data.Message);
      } else {
        this.$message.success(response.data.Message);
        this.couponLocked = true;
      }
      this.couponLoading = false;
    },
    openEditDialog() {
      this.editInvoiceDialogVisible = true;
    },
    openEditProfileDialog() {
      // eslint-disable-next-line no-console
      console.log("openEditProfileDialog");
      this.editProfileDialogVisible = true;
    },

    async getPastAppointment() {
      var res = await this.$client.post("/Appointment/List", {
        PageSize: 5,
        Page: 1,
        Status: 3,
        PastAppointments: true,
        SearchTerm: null,
      });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.pastAppointment = res.data.Data.Appointments;
        this.pastTotalCount = res.data.Data.TotalCount;
      }
    },
    async deleteChild(Id) {
      await this.$store.dispatch("deleteChild", { Id });
    },
    goDetails(id) {
      this.$router.push("/kullanici/gorusme-detay/" + id);
    },
    async deleteCard(cardId) {

        MessageBox.confirm(
      "Seçtiğiniz kartı tamamen silmek istediğinize emin misin? Bu işlem geri alınamaz.",
      "Uyarı",
      {
        confirmButtonText: "Evet, Sil",
        cancelButtonText: "Vazgeç",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      }
    )
      .then(async () => {
        const res = await this.$client.post("/Account/DeletePaymentMethod", {
          ID: cardId,
        });

        if (!res.data.HasError) {
          this.$message.success(res.data.Message);
          await this.$store.dispatch("getUser");
        } else {
          this.$message.error(res.data.Message);
        }
      })
      .catch(() => {

       });
       
    },
  },
  computed: {
    childrenList() {
      return this.$store.getters.getChildrenList;
    },
    filter() {
      return this.$store.getters.getChildrenFilter;
    },
    user() {
      return this.$store.getters.getUser;
    },
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
};
</script>

<style scoped>
.patient-profile {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: #dce2ff;
  .p-head {
    margin-top: 0;
    font-weight: 600;
  }
  .user-info {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    .profile-photo {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      img {
        border-radius: 10px;
      }
      .profile-name {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
        position: absolute;
        bottom: 1rem;
        left: 5%;
        width: 90%;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #000;
        padding: 10px;
        i {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
    .profile-bottom {
      margin-top: 1rem;
      .item {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .coupon-code {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .p-head {
          margin-top: 0;
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.6);
        }
        .coupon-code-input {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }

      .last-appointment {
        margin-top: 1rem;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .p-head {
          color: rgba(0, 0, 0, 0.6);
          margin-top: 0;
        }
        .profile-slider {
          .slider-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            .dr-card {
              display: flex;
              width: 100%;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              .dr-photo {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                aspect-ratio: 1;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .dr-information {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                flex: 1;
                .dr-name {
                  font-weight: 600;
                  font-size: 12px;
                }
                .date {
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.6);
                }
              }
            }
          }
        }
      }
    }
  }
  .right-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
    .saved-credit-card {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

      .p-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);

        .icon-plus {
          width: 24px;
          height: 24px;
          background-color: rgba(0, 0, 0, 0.6);
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      .credit-card-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .credit-card-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          background-color: #f8f9fa;
          border-radius: 8px;
          border: 1px solid #e9ecef;

          .credit-card-info {
            display: flex;
            align-items: center;
            gap: 15px;

            .card-type {
              img {
                width: 40px;
                height: auto;
              }
            }

            .card-details {
              .card-number {
                font-weight: 500;
                color: #212529;
              }
              .card-date {
                font-size: 12px;
                color: #6c757d;
              }
            }
          }

          .card-actions {
            .icon-delete {
              cursor: pointer;
              background-color: #dc3545;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
    .child-list-container {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 10px;
      .p-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
        i {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .child-list-head {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 25px;
        gap: 10px;
        .child-list-head-item {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .child-list-item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 25px;
        gap: 10px;
        .child-list-item-item {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          font-weight: 600;
          &.user-info-sec {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
          }
          .name-short {
            font-size: 12px;
            color: #fff;
            background-color: #2b3e8c;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name-full {
            font-size: 13px;
            color: #000;
            font-weight: 600;
          }
          @media (max-width: 768px) {
            .name-short {
              display: none;
            }
            .name-full {
              font-size: 11px;
            }
          }
        }
        .icon-delete {
              cursor: pointer;
              background-color: #dc3545;
              &:hover {
                opacity: 0.8;
              }
            }
      }
      @media screen and (max-width: 768px) {
        .child-list-head {
          font-size: 12px;
        }
        .child-list-item {
          grid-template-columns: 1fr 1fr 1fr 25px;
          .child-list-item-item {
            font-size: 12px;
            font-weight: 600;
            .name-full {
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .invoice-address-container {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 10px;

      .p-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
        i {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      .invoice-list {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;

        .invoice-list-row {
          display: flex;
          flex-direction: column;
          gap: 10px;

          &:last-child {
            border-bottom: none;
          }

          .invoice-list-label {
            width: 100px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            flex-shrink: 0;
          }

          .invoice-list-value {
            font-size: 13px;
            font-weight: 600;
            color: #000;
          }

          @media screen and (max-width: 768px) {
            .invoice-list-label {
              width: 80px;
              font-size: 11px;
            }
            .invoice-list-value {
              font-size: 11px;
            }
          }
        }
        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}
</style>