import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    countryList: [],
    cityList: [],
    districtList: [],
    policyList: [],
}

export default {
    state,
    getters,
    actions,
    mutations
}