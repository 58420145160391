import { client } from "@/helpers/axiosHelpers";
import { Message, MessageBox } from "element-ui";

export default {
  async getPolicyList(context) {
    var res = await client.post("/Policy/List", {PageSize: 100, Page: 1});
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setPolicyList", res.data.Data.Items);
    }
  },
  async addOrUpdatePolicy(context, payload) {
    context.commit("setPolicyValidation", []);
    var res = await client.post("/Policy/AddOrUpdate", payload);
    context.commit("setPolicyValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.dispatch("getPolicyList", context.state.filter);
      if (payload.ID == null) {
        Message.success("Sözleşme başarıyla eklendi.");
      } else {
        Message.success("Sözleşme başarıyla güncellendi.");
      }
    }
  },
  async deleteOnePolicy(context, payload) {
    MessageBox.confirm("Bu Sözleşmeyi tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
      confirmButtonText: "Evet, Sil",
      cancelButtonText: "Vazgeç",
      customClass: "alert-dialog",
      confirmButtonClass: "primary-btn btn-shadow",
      cancelButtonClass: "secondarary-btn",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Policy/Delete", {
          Id: payload,
        });
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success("Sözleşme başarıyla silindi.");
          await context.dispatch("getPolicyList", context.state.filter);
        }
      })
      .catch(() => { });
  },
  async deleteAllPolicy(context, payload) {
    MessageBox.confirm("Seçilenleri tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
      confirmButtonText: "Evet, Sil",
      cancelButtonText: "Vazgeç",
      customClass: "alert-dialog",
      confirmButtonClass: "primary-btn btn-shadow",
      cancelButtonClass: "secondarary-btn",
      type: "warning",
    })
      .then(async () => {
        await Promise.all(
          payload.map(async (item) => {
            var res = await client.post("/Policy/Delete", {
              Id: item.ID,
            });
            if (res.data.HasError) {
              Message.error(res.data.Message);
            }
          }),
        );
        await context.dispatch("getPolicyList", context.state.filter);
      })
      .catch(() => { });
  },
};
