import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  tests:[],
};
export default {
  getters,
  actions,
  mutations,
  state,
};
